@import "../../scss/main.scss";

.article-container {
  @include background-styles;
  background-position: 100% 30%;
  @media (max-width: 450px) {
    background-position: 100% 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @include article-title;
    margin: 40px 0;
  }
  &__button {
    @include article-button;
    height: 60px;
    width: 100%;
    margin-bottom: map-get($font-sizes, l);
    &:hover {
      background: rgba(255, 255, 255, 0.794);
    }
  }
}

.home-article {
  list-style: none;
  display: flex;
  justify-content: center;

  @media (max-width: 1410px) {
    flex-direction: column;
    align-items: center;
  }
  &__list {
    @include article-container;
    width: 90%;
    margin: 0 20px 20px;
  }

  &__image {
    @include article-image;
  }

  &__title {
    @include article-subtitle;
  }

  &__content {
    @include article-text;
    margin-top: map-get($font-sizes, m);
    width: 80%;
    margin-left: 15px;
  }
  &__button {
    @include article-button;
    margin-top: map-get($font-sizes, xl);
  }

  &__date {
    @include article-date;
  }
}
