@import "../../../scss/main.scss";

.footer {
  background-color: map-get($colors, article-wrapper);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &__wrapper {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: inherit;

    @media (max-width: 690px) {
      flex-direction: column;
    }
  }

  &__image {
    width: 17%;

    @media (max-width: 690px) {
      display: none;
    }
  }

  &__button {
    @include article-button;
  }

  &__main-logo {
    width: 250px;
  }

  &__logo-pzd {
    width: 60px;
    border-radius: 10%;
    margin-top: map-get($margin, s);
  }

  &__elements {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__address {
    font-family: $font-alt;
    color: map-get($colors, footer-font);
    margin-top: map-get($margin, s);
    font-size: map-get($font-sizes, m);

    span {
      display: block;
    }

    @media (max-width: 450px) {
      font-size: map-get($font-sizes, s);
    }
  }

  &__details {
    font-family: $font-alt;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid map-get($colors, footer-line);
    width: inherit;
    padding-top: map-get($margin, s);
    color: map-get($article-colors, art-text);

    p {
      margin-top: 5px;
      @media (max-width: 320px) {
        font-size: map-get($font-sizes, xs);
      }
    }
  }
  &__logo {
    width: 90px;
    height: 60px;
    margin-top: -5px;
    @media (max-width: 320px) {
      width: 80px;
    }
  }
}

.image-rotate {
  transform: rotate(-20deg);
}
