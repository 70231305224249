@import "../../../scss/main.scss";

.organization-element-background {
  @include background-image;
  background-image: url("../../../assets/Images/NavbarImages/pexels-photo-212324.jpeg");
}
.organization-element {
  @include background-styles;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__wrapper {
    background-color: map-get($colors, article-wrapper);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: $round-border;
    width: 90%;
    margin: 40px 0 30px 0;
    padding: 30px 0;
  }
  &__image {
    @include article-image;
  }

  &__title {
    @include article-subtitle;
  }

  &__link {
    @include link;
    margin: 10px 0;
  }
  &__subtitle {
    color: map-get($font-colors, paragraph);
    text-transform: uppercase;
    font-family: $font-alt;
  }
  &__content {
    width: 90%;
  }

  &__paragraph {
    @include paragraph;
  }

  &__text {
    @include article-text;
    margin-left: map-get($margin, s);
  }
}
