@import "../../scss/main.scss";

.article-background {
  @include background-image;
  background-image: url("../../assets/Images/NavbarImages/pexels-photo.jpg");
}
.full-article {
  @include article-background;
  background-position: 50% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__elements {
    @include title-container;
  }

  &__title {
    @include article-title;
  }
  &__link {
    @include link;
  }

  &__wraper {
    @include article-container;
    width: 90%;
    margin-bottom: map-get($margin, l);
  }

  &__image {
    @include article-image;
  }

  &__subtitle {
    @include article-subtitle;
  }

  &__content {
    @include article-text;
    margin-top: map-get($margin, m);
    width: 80%;
    font-weight: 400;
    margin-left: 15px;
  }

  &__date {
    @include article-date;
  }
}
