@import "../../../scss/main.scss";

.organization-background {
  @include background-image;
  background-image: url("../../../assets/Images/NavbarImages/pexels-photo-212324.jpeg");
}
.organization {
  @include information-container;
  @include background-styles;
  background-position: 100% 30%;
  @media (max-width: 450px) {
    background-position: 100% 100%;
  }
  &__elements {
    @include title-container;
  }

  &__title {
    @include article-title;
  }
  &__link {
    @include link;
  }

  &__wrapper {
    @include information-wrapper;
  }

  &__image-wrapper {
    @include information-image-wrapper;
  }

  &__image {
    @include information-image;
    width: 50%;
  }
  &__list {
    @include information-list;
  }
  &__element {
    @include information-element;
  }
  &__element-link {
    @include information-element-link;
  }
  &__element-image {
    @include information-element-icon;
  }
}
