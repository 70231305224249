$round-border: 10px;
$button-border: 32px;

//margin

$margin: (
  s: 10px,
  m: 20px,
  l: 30px,
  xl: 40px,
);
