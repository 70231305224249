@import "../../scss/main.scss";

.articles-background {
  @include background-image;
  background-image: url("../../assets/Images/NavbarImages/pexels-photo.jpg");
}
.articles-container {
  @include article-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: map-get($margin, l);

  &__elements {
    @include title-container;
  }

  &__title {
    @include article-title;
  }
  &__link {
    @include link;
  }
}

.articles {
  list-style: none;
  width: 90%;

  &__list {
    @include article-container;
    margin-bottom: map-get($margin, m);
  }

  &__image {
    @include article-image;
  }

  &__title {
    @include article-subtitle;
  }

  &__content {
    @include article-text;
    width: 80%;
  }
  &__button {
    @include article-button;
    margin-top: map-get($margin, l);
  }

  &__date {
    @include article-date;
  }
}
.articles-addons {
  &__info {
    font-family: $font-alt;
    text-align: center;
    font-size: map-get($font-sizes, s);
    font-weight: bold;
    color: map-get($article-colors, art-text);
    border: 2px dashed map-get($article-colors, art-text);
    padding: 13px;
    border-radius: $button-border;
    margin-top: map-get($margin, m);
    @media (max-width: 360px) {
      width: 250px;
    }
  }

  &__button {
    @include article-button;
    width: 300px;
    @media (max-width: 360px) {
      width: 250px;
    }
  }
}

.article-docTitle {
  @include paragraph;
  @media (max-width: 900px) {
    margin: 5px 0 10px;
  }
}

.article-doc {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  &__element {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include paragraph;
    color: map-get($article-colors, art-text);
    font-size: map-get($font-sizes, s);
    font-weight: 400;
  }

  &__doc {
    @include information-element-link;
    font-size: map-get($font-sizes, s);
    border: 1px solid map-get($article-colors, art-text);
    padding: 3px 10px;
    border-radius: $round-border;
    align-self: center;

    &:hover {
      border-color: map-get($font-colors, hover-effect);
    }
  }
}