@import "../../scss/main.scss";

.gallery-styles-background {
  @include background-image;
  background-image: url("../../assets/Images/NavbarImages/pexels-photo-1675211.jpeg");
}
.gallery {
  @include background-styles;
  background-image: url("../../assets/Images/BackgroundImages/flower-2336287.jpg");
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__elements {
    @include title-container;
  }
  &__title {
    @include article-title;
  }

  &__link {
    @include link;
  }

  &__gif-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  &__pictures {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    margin-bottom: map-get($margin, l);
    @media (max-width: 1105px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 740px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 400px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__picture {
    box-sizing: content-box;
    margin: 10px;
    height: 250px;
    width: 350px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    border-radius: $round-border;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 740px) {
      height: 200px;
      width: 250px;
    }

    @media (max-width: 538px) {
      height: 120px;
      width: 180px;
    }

    @media (max-width: 400px) {
      height: 200px;
      width: 300px;
    }

    @media (max-width: 370px) {
      height: 180px;
      width: 250px;
    }

    @media (max-width: 267px) {
      height: 120px;
      width: 150px;
    }
  }

  .gallery-image {
    height: 250px;
    width: 350px;
    transform: scale(1);
    transition: transform 0.6s ease;

    &:hover {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
    @media (max-width: 740px) {
      height: 200px;
      width: 250px;
    }

    @media (max-width: 538px) {
      height: 120px;
      width: 180px;
    }

    @media (max-width: 400px) {
      height: 200px;
      width: 300px;
    }
    @media (max-width: 370px) {
      height: 180px;
      width: 250px;
    }
    @media (max-width: 267px) {
      height: 120px;
      width: 150px;
    }
  }
  .transparent-box {
    height: 250px;
    width: 350px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
