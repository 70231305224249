@import "../../scss/main.scss";

.home-background {
  @include background-image;
}
.home {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    @include article-title;
    margin: 40px 0;
    span {
      display: block;
    }
  }
  &__wraper {
    display: flex;
    flex-direction: column;
    width: 80%;

    @media (max-width: 1026px) {
      width: 90%;
    }
  }
  &__elements {
    display: flex;
    justify-content: center;
    @media (max-width: 1026px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__text {
    @include article-text;
    flex: 3;
    margin-right: map-get($font-sizes, xl);

    @media (max-width: 1026px) {
      width: 95%;
      margin: auto;
    }
    @media (max-width: 450px) {
      font-size: map-get($font-sizes, s);
      text-align: center;
    }

    @media (max-width: 360px) {
      font-size: 18px;
    }
  }
  &__image {
    align-self: center;
    flex: 1;
    width: 300px;
    height: 300px;
    border-radius: $round-border;
    @media (max-width: 1026px) {
      margin-top: 35px;
    }
    @media (max-width: 450px) {
      width: 65vw;
    }
  }
}
