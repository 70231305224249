@import "../../scss/main.scss";

.gallery-background {
  @include background-image;
  background-image: url("../../assets/Images/NavbarImages/pexels-photo-1144033.jpeg");
}

.gallery-elements {
  @include title-container;

  &__title {
    @include article-title;
  }
  &__link {
    @include link;
  }
}

.gallery-content {
  @include background-styles;
  background-position: 100% 20%;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 450px) {
    background-position: 100% 100%;
  }
  @media (max-width: 1151px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: 100% 100%;
  }

  .gallery-manager {
    margin-bottom: map-get($margin, l);
    &__link {
      text-decoration: none;
    }

    &__container {
      box-sizing: content-box;
      height: 350px;
      width: 500px;
      overflow: hidden;
      position: relative;
      transition: transform 2s, filter 1.5s ease-in-out;
      filter: brightness(50%);
      border-radius: $round-border;
      &:hover {
        cursor: pointer;
      }
      &:hover img {
        transform: scale(1.1);
      }

      &:hover {
        filter: brightness(110%);
      }

      @media (max-width: 540px) {
        height: 250px;
        width: 300px;
      }
      @media (max-width: 360px) {
        height: 150px;
        width: 220px;
      }
    }

    img {
      height: 350px;
      width: 500px;
      transform: scale(1);
      transition: transform 0.9s ease;
      border-radius: inherit;

      @media (max-width: 540px) {
        height: 250px;
        width: 300px;
      }

      @media (max-width: 360px) {
        height: 150px;
        width: 220px;
      }
    }

    &__title {
      @include article-title;
      text-align: center;
      margin-top: map-get($margin, s);
    }
  }
}
