@import "../../../scss/main.scss";

.statute-background {
  @include background-image;
  background-image: url("../../../assets/Images/NavbarImages/strawberry-1531216_1280.webp");
}
.statute {
  @include information-container;
  @include background-styles;
  background-position: 100% 30%;
  @media (max-width: 450px) {
    background-position: 100% 100%;
  }

  &__elements {
    @include title-container;
  }

  &__title {
    @include article-title;
  }

  &__link {
    @include link;
  }
  &__wrapper {
    @include information-wrapper;
    align-items: center;
  }

  &__list {
    list-style: none;
    flex: 1;
    width: 90%;
  }
  &__element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 200px;
  }

  &__subtitle {
    @include paragraph;
    @media (max-width: 450px) {
      font-size: map-get($font-sizes, m);
    }
    @media (max-width: 360px) {
      font-size: map-get($font-sizes, ss);
    }
  }

  &__file {
    @include information-element-link;
    font-size: map-get($font-sizes, ss);
    border: 1px solid map-get($article-colors, art-text);
    padding: 4px 13px;
    border-radius: $round-border;

    &:hover {
      border-color: map-get($font-colors, hover-effect);
    }
  }
}

.prices {
  flex: 1;
  width: 90%;
  &__title {
    @include paragraph;
    @media (max-width: 450px) {
      font-size: map-get($font-sizes, m);
    }
    @media (max-width: 360px) {
      font-size: map-get($font-sizes, ss);
    }
  }

  &__list {
    list-style: none;
    font-size: map-get($font-sizes, s);
    font-family: $font-alt;
    color: map-get($article-colors, art-text);
    width: 98%;
  }
  &__list-elements {
    margin: 15px 0;
  }

  &__elements {
    margin-left: 30px;
  }
  &__subtitle {
    text-align: center;
    margin: 10px 0;
  }

  &__text {
    color: map-get($font-colors, paragraph);
    font-size: map-get($font-sizes, ss);
    text-align: center;
  }

  span {
    margin-right: 8px;
    font-weight: 700;
  }

  &__smaller-text {
    font-size: 15px;
    margin-top: 8px;
  }
}
