@import "../scss/main.scss";

.arrowUp {
  background-image: url("../assets/Icons/up-arrow-site.svg");
  background-repeat: no-repeat;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-position: 50%;
  background-size: 100%;
  position: fixed;
  z-index: 10000;
  bottom: 4%;
  right: 4%;
  cursor: pointer;
}
