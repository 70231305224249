@import "../../scss/main.scss";

.document-background {
  @include background-image;
  background-image: url("../../assets/Images/NavbarImages/pexels-photo-1251397.jpeg");
}
.document {
  @include information-container;
  @include background-styles;
  background-position: 100% 30%;
  @media (max-width: 450px) {
    background-position: 100% 100%;
  }
  &__elements {
    @include title-container;
  }
  &__title {
    @include article-title;
  }
  &__home-link {
    @include link;
  }
  &__wrapper {
    @include information-wrapper;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding: 20px;
    // justify-content: center
  }
  &__image-wrapper {
    @include information-image-wrapper;
  }

  &__image {
    margin-bottom: 20px;
    width: 20%;
  }
  &__list {
    @include information-list;
    padding: 30px;
    @media (max-width: 900px) {
      margin-left: map-get($margin, m);
    }
  }
  &__element {
    margin-bottom: map-get($margin, s);
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 10px;
  }

  &__element-content {
    display: flex;
    align-items: center;
    margin-bottom: map-get($margin, s);
  }

  &__element-name {
    font-family: $font-alt;
    font-size: map-get($font-sizes, ss);
    color: map-get($article-colors, art-text);
    @media (max-width: 450px) {
      font-size: map-get($font-sizes, s);
    }

    @media (max-width: 320px) {
      font-size: 16px;
    }
  }
  &__element-icon {
    @include information-element-icon;
  }
  &__element-link {
    @include information-element-link;
    font-size: map-get($font-sizes, ss);
    border: 1px solid map-get($article-colors, art-text);
    padding: 4px 13px;
    border-radius: $round-border;

    &:hover {
      border-color: map-get($font-colors, hover-effect);
    }
  }
}
